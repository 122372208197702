.mtp-10 {
  margin-top: 10px !important;
}
.mtp-20 {
  margin-top: 20px !important;
}
.mtp-30 {
  margin-top: 30px !important;
}
.mtp-40 {
  margin-top: 40px !important;
}
.mtp-50 {
  margin-top: 50px !important;
}
.mtp-60 {
  margin-top: 60px !important;
}
.mtp-70 {
  margin-top: 70px !important;
}
.mtp-80 {
  margin-top: 80px !important;
}

.mbp-10 {
  margin-bottom: 10px !important;
}
.mbp-20 {
  margin-bottom: 20px !important;
}
.mbp-30 {
  margin-bottom: 30px !important;
}
.mbp-40 {
  margin-bottom: 40px !important;
}
.mbp-50 {
  margin-bottom: 50px !important;
}
.mbp-60 {
  margin-bottom: 60px !important;
}
.mbp-70 {
  margin-bottom: 70px !important;
}
.mbp-80 {
  margin-bottom: 80px !important;
}

@media (min-width: 992px) {
  .mtp-md-10 {
    margin-top: 10px !important;
  }
  .mtp-md-20 {
    margin-top: 20px !important;
  }
  .mtp-md-30 {
    margin-top: 30px !important;
  }
  .mtp-md-40 {
    margin-top: 40px !important;
  }
  .mtp-md-50 {
    margin-top: 50px !important;
  }
  .mtp-md-60 {
    margin-top: 60px !important;
  }
  .mtp-md-70 {
    margin-top: 70px !important;
  }
  .mtp-md-80 {
    margin-top: 80px !important;
  }

  .mbp-md-10 {
    margin-bottom: 10px !important;
  }
  .mbp-md-20 {
    margin-bottom: 20px !important;
  }
  .mbp-md-30 {
    margin-bottom: 30px !important;
  }
  .mbp-md-40 {
    margin-bottom: 40px !important;
  }
  .mbp-md-50 {
    margin-bottom: 50px !important;
  }
  .mbp-md-60 {
    margin-bottom: 60px !important;
  }
  .mbp-md-70 {
    margin-bottom: 70px !important;
  }
  .mbp-md-80 {
    margin-bottom: 80px !important;
  }
}

.ptp-20 {
  padding-top: 20px !important;
}
.ptp-30 {
  padding-top: 30px !important;
}
.ptp-40 {
  padding-top: 40px !important;
}
.ptp-60 {
  padding-top: 60px !important;
}
.ptp-80 {
  padding-top: 80px !important;
}
.ptp-100 {
  padding-top: 100px !important;
}
.ptp-120 {
  padding-top: 120px !important;
}
.ptp-140 {
  padding-top: 140px !important;
}
.ptp-180 {
  padding-top: 180px !important;
}

.pbp-20 {
  padding-bottom: 20px !important;
}
.pbp-30 {
  padding-bottom: 30px !important;
}
.pbp-40 {
  padding-bottom: 40px !important;
}
.pbp-60 {
  padding-bottom: 60px !important;
}
.pbp-80 {
  padding-bottom: 80px !important;
}
.pbp-100 {
  padding-bottom: 100px !important;
}
.pbp-120 {
  padding-bottom: 120px !important;
}
.pbp-140 {
  padding-bottom: 140px !important;
}
.pbp-180 {
  padding-bottom: 180px !important;
}

@media (min-width: 992px) {
  .ptp-md-20 {
    padding-top: 20px !important;
  }
  .ptp-md-30 {
    padding-top: 30px !important;
  }
  .ptp-md-40 {
    padding-top: 40px !important;
  }
  .ptp-md-60 {
    padding-top: 60px !important;
  }
  .ptp-md-80 {
    padding-top: 80px !important;
  }
  .ptp-md-100 {
    padding-top: 100px !important;
  }
  .ptp-md-120 {
    padding-top: 120px !important;
  }
  .ptp-md-140 {
    padding-top: 140px !important;
  }
  .ptp-md-180 {
    padding-top: 180px !important;
  }

  .pbp-md-20 {
    padding-bottom: 20px !important;
  }
  .pbp-md-30 {
    padding-bottom: 30px !important;
  }
  .pbp-md-40 {
    padding-bottom: 40px !important;
  }
  .pbp-md-60 {
    padding-bottom: 60px !important;
  }
  .pbp-md-80 {
    padding-bottom: 80px !important;
  }
  .pbp-md-100 {
    padding-bottom: 100px !important;
  }
  .pbp-md-120 {
    padding-bottom: 120px !important;
  }
  .pbp-md-140 {
    padding-bottom: 140px !important;
  }
  .pbp-md-180 {
    padding-bottom: 180px !important;
  }
}
